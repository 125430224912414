import React from "react";
import img from "./footer.png";

function Footer() {
  return (
    <>
      <div style={{ marginTop: "50px" }}>
        <img src={img} />
      </div>
    </>
  );
}

export default Footer;
