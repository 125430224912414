import React, { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { IoSearch, IoBagOutline } from "react-icons/io5";
import Image1 from "../page_header_bg.png";
import logo from "./logo.PNG";

const navigation = [
  { name: "Shop", href: "#" },
  { name: "Why 2 ", href: "#" },
  { name: "Help", href: "#" },
];

const navigate = [
  {
    name: "My 02",
    href: "#",
  },
  {
    name: "Search",
    icon: <IoSearch />,
    href: "#",
  },
  {
    icon: <IoBagOutline />,
  },
];

const Navbar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <>
      <div className="">
        <div className="mx-auto max-w-8xl border-b border-gray-300 py-1 lg:px-8 flex items-center justify-around">
          <div className="flex space-x-3 ">
            <a href="" className="text-indigo-700">
              Personal
            </a>
            <p>|</p>
            <a href="" className="text-indigo-700">
              Business
            </a>
          </div>
          <div className="flex justify-end">
            <a href="" className="text-indigo-700">
              Sign In or Register
            </a>
          </div>
        </div>
      </div>
      <header className="bg-white">
        <nav
          className="mx-auto  flex max-w-8xl items-center justify-around  lg:px-8"
          aria-label="Global"
          style={{
            width: "100%",
            height: "80px",
            backgroundColor: "rgb(0,25,165)",
            borderBottom: "1px solid white",
          }}
        >
          <div className="flex items-center gap-x-12">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img className="h-8 w-auto" src={logo} alt="Logo Here" />
            </a>
            <div className="hidden lg:flex lg:gap-x-12">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-md font-semibold leading-6 text-white"
                >
                  {item.name}
                </a>
              ))}
            </div>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {navigate.map((item, index) => (
              <div
                key={index}
                className="flex justify-center items-center space-x-2"
              >
                <div className="text-md text-white">{item?.icon}</div>
                <a
                  href={item?.href}
                  className="text-md font-semibold leading-6 text-white"
                >
                  {item?.name}
                </a>
              </div>
            ))}
          </div>
        </nav>
        <Dialog
          as="div"
          className="lg:hidden"
          open={mobileMenuOpen}
          onClose={setMobileMenuOpen}
        >
          <div className="fixed inset-0 z-10" />
          <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
            <div className="flex items-center justify-between">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img className="h-8 w-auto" src={logo} alt="Logo Here" />
              </a>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {navigation.map((item) => (
                    <a
                      key={item.name}
                      href={item.href}
                      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50"
                    >
                      {item.name}
                    </a>
                  ))}
                </div>
                <div className="space-y-2 py-6">
                  {navigate.map((item, index) => (
                    <div
                      key={index}
                      className="flex lg:justify-center justify-start items-center lg:space-x-2 space-x-[-10px]"
                    >
                      <div className="text-md lg:flex hidden text-white">
                        {item?.icon}
                      </div>
                      <a
                        key={item.name}
                        href={item.href}
                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:bg-gray-50"
                      >
                        {item.name}
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog>
        <div className=" w-100">
          <img style={{ width: "100%", height: "20vh" }} src={Image1} alt="" />
          <p
            style={{
              marginTop: "-8%",
              marginLeft: "15%",
              color: "white",
              fontSize: "2.5rem",
            }}
          >
            Sign in{" "}
          </p>
        </div>
      </header>
    </>
  );
};

export default Navbar;
